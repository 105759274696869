import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { observer } from 'mobx-react-lite'
import { useMemo, useState, useEffect } from 'react'

import { noExponents } from '@/utils/helpers/format'

import Select from '@/components/Select'

import { COLORS } from '@/constants'

import styles from './index.module.css'


const MyLineChart = ({
  report,
  allowedFields,
  standardId,
  divisions,
  toYear,
  halfyear,
  countYears,
}) => {
  const [field, setField] = useState()

  const years = useMemo(() => {
    if (!toYear) return []
    if (halfyear) {
      return Array(countYears * 2).fill(0)
        .map((_, i) => (`${+toYear - countYears + 1 + (Number.parseInt(i / 2))} (${(i % 2) + 1})`))
    }
    return Array(countYears).fill(0).map((_, i) => (+toYear - countYears + 1 + i))
  }, [toYear, halfyear, countYears])

  const reportData = report.get('data.fields', [])
  const reportLoaded = report.loaded

  const fields = useMemo(() => {
    return allowedFields
      .filter(f => f.standardId === standardId)
      .sort((a, b) => {
        if (!a.orderNumber || !b.orderNumber) {
          return 0
        }
        return a.orderNumber - b.orderNumber
      })
      .map(f => ({ value: f.id, label: f.name }))
  }, [allowedFields, standardId])

  useEffect(() => {
    if (!field) {
      setField(fields?.[0]?.value)
    }
  }, [field, fields])

  const currentField = useMemo(() => {
    return allowedFields.find(f => f.id === field)
  }, [allowedFields, field])

  const unit = currentField?.unit
  const multiplier = unit?.multiplier || 1

  const data = useMemo(() => {
    if (!reportLoaded) return []
    const result = {}
    years.forEach(year => {
      divisions.forEach(div => {
        const v = reportData
          .find(rd => {
            return (
              rd.fieldId === field &&
              rd?.division?.id === div.id &&
              (halfyear ? year === `${rd.year} (${rd.halfyear})` : year === rd.year)
            )
          })
        if (!result[year]) {
          result[year] = { year }
        }
        result[year][div.id] = v?.value ? v.value / multiplier : 0
      })
    })

    return Object.values(result)
  }, [reportLoaded, years, divisions, reportData, multiplier, field, halfyear])

  return (
    <div className={styles.root}>
      <Select
        className={styles.select}
        options={fields}
        values={field ? [field] : []}
        onChange={v => setField(v[0])}
      />
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width="100%"
            height={340}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip
              formatter={value => {
                const unit = currentField?.unit
                let significand = currentField?.significand
                significand = significand === null || significand === undefined ? 2 : significand
                if (!value) return 0 + ' ' + unit?.name
                return noExponents(value, significand) + ' ' + unit?.name
              }}
            />
            {divisions.map((d, i) => (
              <Line
                key={d.id}
                type="monotone"
                dataKey={d.id}
                stroke={COLORS[i % COLORS.length]}
                name={d.name}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default observer(MyLineChart)
