import classNames from 'classnames'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useNavigate, useLocation } from 'react-router-dom'

import PageNav from '@/components/PageNav'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const subPages = [
  {
    id: 'total',
    name: 'Сводные данные',
  },
  {
    id: 'rows',
    name: 'База данных',
  },
]

const Modules = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === '/data') {
      navigate(`/data/${subPages[0].id}`, { replace: true })
    }
  }, [pathname, navigate])

  return (
    <div className={classNames(
      pageStyles.page,
      styles.root
    )}>
      <PageNav links={subPages}/>
      <Outlet/>
    </div>
  )
}

export default Modules
