import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { useEffect, useContext, useState, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import Select from '@/components/Select'

import { StoreContext } from '@/store'

import { FIELD_TYPE_DICT } from '@/constants'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const Fields = () => {
  const store = useContext(StoreContext)
  const { httpStore, formsStore, authStore } = store
  const [changed, setChanged] = useState(false)
  const navigate = useNavigate()
  const { standardId } = useParams()

  const formName = 'companyAllowFieldsOrder'
  const form = formsStore.getForm(formName)

  const company = authStore?.user?.company

  const standardsArgs = [
    'listStandards',
    ['id', 'name', { resources: ['id', 'name'] }],
    undefined, {
      cacheTime: 60 * 60 * 1000,
    },
  ]

  const standards = httpStore.fetchRequest(...standardsArgs).get('data', [])

  const standardsDict = useMemo(() => {
    return standards.reduce((sMemo, s) => {
      return {
        ...sMemo,
        [s.id]: {
          name: s.name,
          resources: s.resources.reduce((rMemo, r) => {
            return {
              ...rMemo,
              [r.id]: {
                name: r.name,
              },
            }
          }, {}),
        },
      }
    }, {})
  }, [standards])

  useEffect(() => {
    formsStore.createForm(formName, {
      form: {
        data: getSnapshot(company),
      },
    }, true)
  }, [company, formsStore])

  let allowedFields
  if (form) {
    allowedFields = form.form.get('data.allowedFields', [])
  }

  const allowedStandards = useMemo(() => {
    return (allowedFields || [])
      .map(f => f.standardId)
      .filter((s, i, arr) => arr.indexOf(s) === i)
      .map(s => ({ value: s, label: standardsDict[s]?.name }))
  }, [allowedFields, standardsDict])

  useEffect(() => {
    if (allowedStandards[0]) {
      if (!standardId || !allowedStandards.some(s => s.value === standardId)) {
        navigate(`/fields/${allowedStandards[0].value}`, { replace: true })
      }
    }
  }, [allowedStandards, navigate, standardId])
  
  const handleSortChange = useCallback(vals => {
    const newAllowedFields = allowedFields.map(af => {
      return vals.find(v => v.id === af.id) || af
    })

    form.form.setValue('data.allowedFields', newAllowedFields)
    setChanged(true)
  }, [allowedFields, form?.form])

  if (!allowedFields || !standardId) return null

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Настройка порядка полей
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.fill}
            color={BUTTON_COLORS.blue}
            disabled={form?.form?.hasErrors || !changed}
            onClick={() => form?.form.submit(
              'upsertCompany',
              [
                'id',
                'name',
                {
                  allowedFields: [
                    'id',
                    'name',
                    'type',
                    'formula',
                    'significand',
                    { unit: ['id', 'name', 'multiplier', 'parentId'] },
                    'orderNumber',
                    'resourceId',
                    'standardId',
                    'companyId',
                  ],
                },
              ],
              v => ({
                company: {
                  ...v,
                  allowedFields: (v.allowedFields || []).map(af => ({ id: af.id, orderNumber: af.orderNumber })),
                },
              })
            ).then(({ data }) => {
              authStore.setCompany(data)
              setChanged(false)
              httpStore.resetCallTime()
            })}
          >
            Сохранить
          </Button>
        </div>
      </div>
      <div className={pageStyles.filters}>
        <Select
          className={styles.standardSelect}
          options={allowedStandards}
          values={[standardId]}
          onChange={vals => navigate(`/fields/${vals[0]}`)}
        />
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={allowedFields.filter(f => f.standardId === standardId)}
          dragKey="id"
          sortField="orderNumber"
          onSortChange={handleSortChange}
          head={[
            {
              title: 'ID',
              className: styles.idColumn,
            },
            {
              title: 'Стандарт',
            },
            {
              title: 'Тип поля',
            },
            {
              title: 'Название',
            },
            {
              title: 'Тип данных',
            },
            {
              title: 'Ед. изм.',
            },
            {
              title: 'Формула',
            },
          ]}
          body={[
            {
              model: item => item.id,
            },
            {
              model: item => standardsDict[item.standardId]?.resources?.[item.resourceId]?.name || '---',
            },
            {
              model: item => item.formula ? 'Специальное' : 'Основное',
            },
            {
              model: item => item.name,
            },
            {
              model: item => FIELD_TYPE_DICT[item.type] || item.type,
            },
            {
              model: item => item.unit?.name,
            },
            {
              model: item => item.formula || '',
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Fields)
