import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { observer } from 'mobx-react-lite'
import { useMemo, useState, useEffect } from 'react'

import { noExponents } from '@/utils/helpers/format'

import Select from '@/components/Select'

import { COLORS } from '@/constants'

import styles from './index.module.css'


const MyPieChart = ({
  report,
  allowedFields,
  standardId,
}) => {
  const [field, setField] = useState()

  const reportData = report.get('data.fields', [])
  const reportLoaded = report.loaded

  const fields = useMemo(() => {
    return allowedFields
      .filter(f => f.standardId === standardId)
      .sort((a, b) => {
        if (!a.orderNumber || !b.orderNumber) {
          return 0
        }
        return a.orderNumber - b.orderNumber
      })
      .map(f => ({ value: f.id, label: f.name }))
  }, [allowedFields, standardId])

  useEffect(() => {
    if (!field) {
      setField(fields?.[0]?.value)
    }
  }, [field, fields])

  const currentField = useMemo(() => {
    return allowedFields.find(f => f.id === field)
  }, [allowedFields, field])

  const data = useMemo(() => {
    if (!reportLoaded) return []
    return reportData
      .filter(d => d.fieldId === field)
      .map(d => ({ value: +d.value , division: d.division.name }))
  }, [reportLoaded, reportData, field])

  return (
    <div className={styles.root}>
      <Select
        className={styles.select}
        options={fields}
        values={field ? [field] : []}
        onChange={v => setField(v[0])}
      />
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={340} height={340}>
            <Pie
              dataKey="value"
              nameKey="division"
              data={data}
              innerRadius={100}
              outerRadius={170}
              minAngle={2}
              fill="#82ca9d"
            >
              {data.map((d, i) => (
                <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              formatter={value => {
                const unit = currentField?.unit
                const multiplier = unit?.multiplier || 1
                let significand = currentField?.significand
                significand = significand === null || significand === undefined ? 2 : significand
                if (!value) return 0 + ' ' + unit?.name
                return noExponents(value / multiplier, significand) + ' ' + unit?.name
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default observer(MyPieChart)
