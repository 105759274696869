import { useMemo } from 'react'
import classNames from 'classnames'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import SortableRow from './components/SortableRow'

import styles from './index.module.css'

const Table = ({
  className,
  data: tmpData,
  head,
  body = [],
  sortField,
  onSortChange,
  dragKey, // Values must be Unique
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  )

  const isDrag = !!onSortChange && !!sortField && !!dragKey

  const data = useMemo(() => {
    if (!isDrag || !tmpData) return tmpData
    return [...tmpData].sort((a, b) => {
      if (!a[dragKey] || !b[dragKey] === undefined) {
        console.error(a, b)
        throw new Error('Some items hasn\'t dragKey value')
      }
      return a[sortField] - b[sortField]
    })
  }, [dragKey, isDrag, sortField, tmpData])

  const handleDragEnd = (event) => {
    const { active, over } = event
    const activeKey = active?.id
    const overKey = over?.id

    if (activeKey !== overKey) {
      const oldIndex = data.findIndex((item) => item[dragKey] === activeKey)
      const newIndex = data.findIndex((item) => item[dragKey] === overKey)
      const updatedData = arrayMove(data, oldIndex, newIndex).map((item, index) => ({
        ...item,
        [sortField]: index + 1,
      }))

      onSortChange(updatedData)
    }
  }

  const tableContent = (
    <div className={classNames(styles.wrapper1, className)}>
      <div className={styles.wrapper2}>
        <table className={styles.root}>
          <thead>
            <tr>
              {isDrag &&
                <th className={styles.drag} key={'dragDots'}>
                  <Icon className={styles.dragDots} type={ICONS_TYPES.dragDots} width={10} height={16} />
                </th>
              }
              {head.map(({ title, ...props }, i) => (
                <th key={i} {...props}>{title}</th>
              ))}
            </tr>
          </thead>
          {onSortChange ? (
            <SortableContext
              items={data.map(item => item[dragKey] ?? item)}
              strategy={verticalListSortingStrategy}>
              <tbody>
                {data.map((item, index) => (
                  <SortableRow
                    key={index}
                    item={item}
                    body={body}
                    dragKey={dragKey}
                    isDrag={isDrag}
                  />
                ))}
              </tbody>
            </SortableContext>
          ) : (
            <tbody>
              {data.map((item, index) => (
                <SortableRow
                  key={index}
                  item={item}
                  body={body}
                />
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )

  return onSortChange ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      {tableContent}
    </DndContext>
  ) : (
    tableContent
  )
}

export default Table
