import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import Table from '@/components/Table'

import { noExponents } from '@/utils/helpers/format'

import styles from './index.module.css'


const hasData = (data = {}) => {
  return Object.values(data).some(v => !!v)
}

const DataTable = ({
  halfyear,
  report,
  countYears,
  allowedFields,
  standardId,
  toYear,
}) => {
  const years = useMemo(() => {
    if (!toYear) return []
    if (halfyear) {
      return Array(countYears * 2).fill(0)
        .map((_, i) => (`${+toYear - countYears + 1 + (Number.parseInt(i / 2))} (${(i % 2) + 1})`))
    }
    return Array(countYears).fill(0).map((_, i) => (+toYear - countYears + 1 + i))
  }, [toYear, halfyear, countYears])
  
  const reportData = report.get('data.fields', [])
  const reportLoaded = report.loaded

  const data = useMemo(() => {
    if (!reportLoaded) return []
    const sortedFields = allowedFields
      .filter(f => f.standardId === standardId)
      .sort((a, b) => {
        if (!a.orderNumber || !b.orderNumber) {
          return 0
        }
        return a.orderNumber - b.orderNumber
      })
    return sortedFields.map(field => ({
      field,
      years: years.reduce((memo, year) => ({
        ...memo,
        [year]: reportData
          .find(rd => {
            if (halfyear) {
              return rd.fieldId === field.id && `${rd.year} (${rd.halfyear})` === year
            }
            return rd.fieldId === field.id && rd.year === year
          }),
      }), {}),
    }))
  }, [reportLoaded, allowedFields, standardId, years, reportData, halfyear])

  return (
    <Table
      className={styles.root}
      data={data}
      head={[
        {
          title: 'Название',
          className: styles.nameColumn,
        },
        {
          title: 'Ед. изм.',
        },
        ...years.map(y => ({ title: y })),
      ]}
      body={[
        {
          model: item => item.field?.name,
          getProps: item => {
            const isHasData = hasData(item.years)
            if (!isHasData) {
              return {
                colSpan: 2 + (countYears * (halfyear ? 2 : 1)),
              }
            }
            return {}
          },
        },
        {
          model: item => {
            const isHasData = hasData(item.years)
            if (!isHasData) return null
            return item.field?.unit?.name
          },
        },
        ...years.map(y => ({
          model: item => {
            const isHasData = hasData(item.years)
            if (!isHasData) return null
            const value = item.years?.[y]?.value
            const multiplier = item.field?.unit?.multiplier || 1
            let significand = item.field?.significand
            significand = significand === null || significand === undefined ? 2 : significand
            if (!value) return 0
            return noExponents(value / multiplier, significand)
          },
        })),
      ]}
    />
  )
}

export default observer(DataTable)
