import { useCallback, useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import Label from '@/components/Label'

import { isDefAndNotNull } from '@/utils/helpers/def'

import { EMPTY_VALUE_ERR_MSG } from '@/constants'

import { TYPE_TO_FORMAT, TYPE_TO_TYPE, TYPE_TO_UNFORMAT, TYPE_TO_VALIDATE } from './constants'
import styles from './index.module.css'


let caretPosition

const Input = ({
  className,
  type,
  label,
  placeholder,
  disabled,
  value,
  error,
  required,
  checkOnBlur = true,
  children,
  customFormat,
  allowRegex,
  onChange,
  onError,
  ...other
}) => {
  const inputRef = useRef()
  const [formattedValue, setFormattedValue] = useState('')
  const [isBlured, setIsBlured] = useState(false)

  const format = customFormat?.format || TYPE_TO_FORMAT[type]
  const unformat = format ? customFormat?.unformat || TYPE_TO_UNFORMAT[type] : undefined

  const validate = customFormat?.validate || TYPE_TO_VALIDATE[type]

  useEffect(() => {
    const newValue = format ? format(value) : value
    setFormattedValue(newValue)
  }, [value, format])

  useEffect(() => {
    let errorMsg
    if (value && validate && onError) {
      errorMsg = validate.isValid(value) ? undefined : validate.error
    } else if (required && onError) {
      errorMsg = value?.length ? undefined : EMPTY_VALUE_ERR_MSG
    }
    if (error !== errorMsg) {
      onError(errorMsg)
    }
  }, [value, error, validate, required, onError])

  useEffect(() => {
    if (isDefAndNotNull(caretPosition)) {
      inputRef.current.setSelectionRange(caretPosition, caretPosition)
      caretPosition = undefined
    }
  }, [formattedValue])

  const handleChange = useCallback((e) => {
    if (!disabled && onChange) {
      const nextValue = e.target.value || ''
      if (nextValue.length !== e.target.selectionStart) {
        if (format) {
          const partValue = nextValue.substring(0, e.target.selectionStart)
          const formatPartValue = format(partValue).toString()
          const formatValue = format(nextValue).toString()
          caretPosition = formatPartValue.length + formatValue.indexOf(formatPartValue)
        } else {
          caretPosition = e.target.selectionStart
        }
      }
      const newValue = unformat ? unformat(nextValue) : nextValue
      onChange(newValue)
    }
  }, [onChange, disabled, format, unformat])

  const handleBlur = useCallback(() => setIsBlured(true), [])

  const handleKeyDown = useCallback((e) => {
    if (allowRegex instanceof RegExp) {
      if (e.key.length === 1 && !allowRegex.test(e.key)) {
        e.preventDefault()
      }
    }
  }, [allowRegex])

  const realType = TYPE_TO_TYPE[type] || 'text'

  const showError = (!checkOnBlur || isBlured) && !!error

  if (type === 'multi') {
    return (
      <div
        className={classNames(className, styles.root, showError && styles.error)}>
        <Label label={label} disabled={disabled} />
        <textarea
          ref={inputRef}
          className={styles.input}
          disabled={disabled}
          placeholder={placeholder}
          value={formattedValue}
          onInput={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...other}
        />
        {children}
        {showError && (
          <span className={styles.errorMessage}>{error}</span>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(className, styles.root, showError && styles.error)}>
      <Label label={label} disabled={disabled} />
      <input
        ref={inputRef}
        className={styles.input}
        disabled={disabled}
        type={realType}
        placeholder={placeholder}
        value={formattedValue}
        onInput={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        {...other}
      />
      {children}
      {showError && (
        <span className={styles.errorMessage}>{error}</span>
      )}
    </div>
  )
}

export default Input
