import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { StoreContext } from '@/store'

import Select from '@/components/Select'
import Toggler from '@/components/Toggler'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'

import DataTable from './components/DataTable'
import PieChart from './components/PieChart'
import LineChart from './components/LineChart'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const FULL_YEARS = 5
const HALF_YEARS = 3

const Root = () => {
  const store = useContext(StoreContext)
  const navigate = useNavigate()
  const { standardId, toYear } = useParams()
  const [division, setDivision] = useState()
  const [halfyear, setHalfyear] = useState(false)

  const countYears = halfyear ? HALF_YEARS : FULL_YEARS

  const { httpStore, authStore } = store

  const allowedFields = authStore?.user?.company?.allowedFields
  const allowedFieldsSnapshot = getSnapshot(allowedFields)
  const companyId = authStore?.user?.company?.id
  const divisionId = authStore?.user?.division?.id

  const divisionsArgs = [
    'listDivisions',
    ['id', 'name'],
    undefined,
  ]

  const divisions = httpStore.fetchRequest(...divisionsArgs).get('data', [])

  const standardsArgs = [
    'listStandards',
    ['id', 'name', 'moduleId'],
    allowedFields && { standardIds: allowedFields.map(af => af.standardId).filter((s, i, a) => a.indexOf(s) === i) },
    {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const standards = httpStore.fetchRequest(...standardsArgs).get('data', [])

  const currentStandard = standards.find(s => s.id === standardId)
  const moduleId = currentStandard?.moduleId

  useEffect(() => {
    if (standards[0]) {
      if (!standardId || !standards.some(s => s.id === standardId)) {
        navigate(`/report/${standards[0].id}`, { replace: true })
      }
    }
  }, [standards, navigate, standardId])

  useEffect(() => {
    if (standardId && !toYear) {
      navigate(`/report/${standardId}/${(new Date()).getFullYear()}`, { replace: true })
    }
  }, [navigate, standardId, toYear])

  const yearsFull = useMemo(() => {
    if (!toYear) return []
    const years = 20
    return Array(years).fill(0).map((_, i) => (+toYear - (years / 2) + i))
  }, [toYear])

  let reportTable = {
    get: () => ([]),
    loaded: false,
  }
  if (standardId && toYear) {
    const reportArgs = [
      'loadReport',
      [{ fields: ['fieldId', 'halfyear', 'value', 'year', { division: ['id', 'name'] }] }],
      {
        timeAggregation: `@enum(${halfyear ? 'HALFYEAR' : 'YEAR'})`,
        unitAggregation: '@enum(COMPANY)',
        fromYear: toYear - countYears + 1,
        toYear: +toYear,
        companyId,
        divisionId: division,
      },
    ]
    reportTable = httpStore.fetchRequest(...reportArgs)
  }

  let reportPie = {
    get: () => ([]),
    loaded: false,
  }
  if (standardId && toYear) {
    const reportArgs = [
      'loadReport',
      [{ fields: ['fieldId', 'value', { division: ['id', 'name'] }] }],
      {
        timeAggregation: '@enum(TOTAL)',
        unitAggregation: '@enum(DIVISION)',
        fromYear: toYear - countYears + 1,
        toYear: +toYear,
        companyId,
      },
    ]
    reportPie = httpStore.fetchRequest(...reportArgs)
  }

  let reportLine = {
    get: () => ([]),
    loaded: false,
  }
  if (standardId && toYear) {
    const reportArgs = [
      'loadReport',
      [{ fields: ['fieldId', 'halfyear', 'value', 'year', { division: ['id', 'name'] }] }],
      {
        timeAggregation: `@enum(${halfyear ? 'HALFYEAR' : 'YEAR'})`,
        unitAggregation: '@enum(DIVISION)',
        fromYear: toYear - countYears + 1,
        toYear: +toYear,
        companyId,
      },
    ]
    reportLine = httpStore.fetchRequest(...reportArgs)
  }

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Отчет
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const dataArgs = [
                'loadReportXlsx',
                undefined,
                {
                  fromYear: toYear - countYears + 1,
                  toYear: +toYear,
                  companyId,
                  moduleId,
                },
                {
                  onSuccess: resp => {
                    const data = resp.get('data', '')
                    const blob = new Blob(
                      [Uint8Array.from(atob(data), (m) => m.codePointAt(0))],
                      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                    )
                    const fileURL = URL.createObjectURL(blob)
                    const downloadLink = document.createElement('a')
                    downloadLink.href = fileURL
                    downloadLink.download = `report${Date.now()}.xlsx`
                    document.body.appendChild(downloadLink)
                    downloadLink.click()
                    downloadLink.remove()
                  },
                },
              ]

              httpStore.fetchRequest(...dataArgs)
            }}
          >
            <span>&#x2913; Скачать Excel</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.filters}>
        <Select
          className={styles.standardSelect}
          options={standards.map(s => ({ value: s.id, label: s.name }))}
          values={[standardId]}
          onChange={vals => navigate(`/report/${vals[0]}`)}
        />
        {!divisionId && (
          <Select
            placeholder="Выберите подразделение"
            className={styles.standardSelect}
            options={divisions.map(d => ({ value: d.id, label: d.name }))}
            values={division ? [division] : []}
            onChange={vals => setDivision(vals[0])}
          />
        )}
      </div>
      <div className={pageStyles.filters}>
        <Select
          className={styles.yearSelect}
          options={yearsFull.map(y => ({ value: y }))}
          values={[+toYear]}
          onChange={vals => navigate(`/report/${standardId}/${vals[0]}`)}
        />
        <Toggler value={halfyear} onChange={setHalfyear} label="По полугодиям"/>
      </div>
      <div className={classNames(pageStyles.block, styles.tableBlock)}>
        <DataTable
          halfyear={halfyear}
          report={reportTable}
          countYears={countYears}
          allowedFields={allowedFieldsSnapshot}
          standardId={standardId}
          toYear={toYear}
        />
      </div>
      <div className={styles.charts}>
        <div className={classNames(pageStyles.block, styles.pieChart)}>
          <PieChart
            report={reportPie}
            allowedFields={allowedFieldsSnapshot}
            standardId={standardId}
          />
        </div>
        <div className={classNames(pageStyles.block, styles.lineChart)}>
          <LineChart
            report={reportLine}
            allowedFields={allowedFieldsSnapshot}
            standardId={standardId}
            divisions={divisions}
            halfyear={halfyear}
            countYears={countYears}
            toYear={toYear}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(Root)
