import Modal from '@/components/Modal'
import { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const MessageBoxModal = ({ children, title, text, onClose, form, onSuccess, ...other }) => (
  <Modal
    title={title}
    type="center"
    className={styles.root}
    width={460}
    onClose={onClose}
    buttons={[
      {
        type: BUTTON_TYPES.border,
        children: 'OK',
        onClick: onClose,
      },
    ]}
    {...other}
  >
    {text}
  </Modal>
)

export default MessageBoxModal
