import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'

import Icon, { ICONS_TYPES } from '@/components/Icon'
import Button from '@/components/Button'

import useClickOutside from '@/utils/hooks/useClickOutside'

import styles from './index.module.css'
import { SEARCH_TYPES } from './constants'

let debounceTimeoutId = null

const Search = forwardRef(({
  className,
  style,
  delay = 500,
  type = SEARCH_TYPES.default,
  onSearch = () => {},
  ...other
}, ref) => {
  const [searchValue, setSearchValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const rootRef = useRef(null)

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleCancel()
    },
  }))

  const handleChange = (e) => {
    setSearchValue(e.target.value)

    clearTimeout(debounceTimeoutId)
    debounceTimeoutId = setTimeout(() => {
      onSearch(e.target.value)
    }, delay)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(debounceTimeoutId)
      onSearch(searchValue)
    }
  }

  const handleSearch = () => {
    clearTimeout(debounceTimeoutId)
    onSearch(searchValue)
  }

  const handleCancel = () => {
    onSearch('')
    setSearchValue('')
    clearTimeout(debounceTimeoutId)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  useClickOutside(rootRef, () => {
    setIsFocused(false)
  })

  return (
    <div
      className={classNames(
        styles.root,
        styles[type],
        isFocused && styles.focused,
        className
      )}
      style={style}
      ref={rootRef}
      {...other}
    >
      <input
        {...other}
        type='text'
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
      />
      {!searchValue &&
        <Icon
          className={classNames(styles['icon'], styles['icon-search'])}
          type={ICONS_TYPES.search}
        />
      }
      {searchValue &&
        <Icon
          className={styles['icon']}
          type={ICONS_TYPES.delete}
          onClick={handleCancel}
        />
      }
      {type === SEARCH_TYPES.default && searchValue && isFocused &&
        <Button
          className={styles['button-search']}
          onClick={handleSearch}
        >
          Найти
        </Button>
      }
    </div>
  )
})

export default Search


export { SEARCH_TYPES } from './constants'
