import classNames from 'classnames'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const SortableRow = ({ className, item, body, isDrag = false, dragKey }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item[dragKey],
    disabled: !isDrag,
  })

  if (!item || !body) return null

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <tr
      className={classNames(styles.root, className)}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isDrag && (
        <td className={styles.drag}>
          <Icon className={styles.dragDots} type={ICONS_TYPES.dragDots} width={10} height={16} />
        </td>
      )}
      {body.map(({ model = () => null, getProps = () => ({}), ...props }, k) => {
        const data = model(item, k)
        if (data === null) return null
        const calcProps = getProps(item, k)
        return (
          <td key={k} {...props} {...calcProps}>{data}</td>
        )
      })}
    </tr>
  )
}

export default SortableRow
