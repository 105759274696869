import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { StoreContext } from '@/store'

import Table from '@/components/Table'
import Select from '@/components/Select'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import FileInput, { FILE_INPUT_COLORS, FILE_INPUT_TYPES } from '@/components/FileInput'
import DropDownList from '@/components/DropDownList'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import { noExponents } from '@/utils/helpers/format'
import { HALF_YEAR_DICT } from '@/constants'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'
import classNames from 'classnames'


const DataRows = () => {
  const store = useContext(StoreContext)
  const navigate = useNavigate()
  const { standardId } = useParams()

  const { httpStore, formsStore, authStore } = store

  const allowedFields = authStore?.user?.company?.allowedFields
  const companyId = authStore?.user?.company?.id
  const divisionId = authStore?.user?.division?.id

  const standardsArgs = [
    'listStandards',
    ['id', 'name', { resources: ['id', 'name'] }],
    allowedFields && { standardIds: allowedFields.map(af => af.standardId).filter((s, i, a) => a.indexOf(s) === i) },
    {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const standards = httpStore.fetchRequest(...standardsArgs).get('data', [])

  const fieldValuesArgs = [
    'listFieldValues',
    [
      'id',
      'value',
      'year',
      'halfyear',
      { division: ['id', 'name'] },
      { field: ['id', 'name', { unit: ['id', 'name'] }, 'standardId', 'resourceId' ] },
    ],
    { standardId, companyId, departmentId: divisionId },
    {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const fieldValues = httpStore.fetchRequest(...fieldValuesArgs).get('data', [])

  const standardsDict = useMemo(() => {
    return standards.reduce((sMemo, s) => {
      return {
        ...sMemo,
        [s.id]: {
          name: s.name,
          resources: s.resources.reduce((rMemo, r) => {
            return {
              ...rMemo,
              [r.id]: {
                name: r.name,
              },
            }
          }, {}),
        },
      }
    }, {})
  }, [standards])

  const allowedFieldsDict = useMemo(() => {
    return allowedFields.reduce((memo, curr) => ({
      ...memo,
      [curr.id]: curr,
    }), {})
  }, [allowedFields])

  const allowedStandards = useMemo(() => {
    return (allowedFields || [])
      .map(f => f.standardId)
      .filter((s, i, arr) => arr.indexOf(s) === i)
      .map(s => ({ value: s, label: standardsDict[s]?.name }))
  }, [allowedFields, standardsDict])

  useEffect(() => {
    if (allowedStandards[0]) {
      if (!standardId || !allowedStandards.some(s => s.value === standardId)) {
        navigate(`/data/rows/${allowedStandards[0].value}`, { replace: true })
      }
    }
  }, [allowedStandards, navigate, standardId])

  const currResources = standardsDict[standardId]?.resources

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          База данных
        </h1>
        <div className={classNames(pageStyles.headerControls, styles.header)}>
          <div className={styles.helper}>
            <div>?</div>
            <a href="/air_data_template.xlsx" download>
              Скачать шаблон Excel для загрузки данных
            </a>
          </div>
          <FileInput
            type={FILE_INPUT_TYPES.dashed}
            color={FILE_INPUT_COLORS.blue}
            operationName="uploadFields"
            onChange={() => {
              httpStore.resetCallTime()
              httpStore.fetchRequest(...fieldValuesArgs)
            }}
            onError={console.dir}
          >
            <span>&#x2912; Загрузить записи</span>
          </FileInput>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createFieldValue'
              formsStore.createForm(formName, {
                modalComponent: 'FieldValueEditModal',
                props: {
                  standardId,
                  onSuccess: () => {
                    httpStore.resetCallTime()
                    httpStore.fetchRequest(...fieldValuesArgs)
                  },
                },
              })
            }}
          >
            <span>+ Создать запись</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.filters}>
        <Select
          className={styles.standardSelect}
          options={allowedStandards}
          values={[standardId]}
          onChange={vals => navigate(`/data/rows/${vals[0]}`)}
        />
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={fieldValues}
          head={[
            {
              title: 'Стандарт',
            },
            {
              title: 'Подразделение',
            },
            {
              title: 'Название',
            },
            {
              title: 'Период',
            },
            {
              title: 'Значение',
            },
            {
              title: 'Ед. изм.',
            },
            {
              className: styles.dotMenu,
            },
          ]}
          body={[
            {
              model: item => currResources?.[item.field?.resourceId]?.name,
            },
            {
              model: item => item.division?.name,
            },
            {
              model: item => item.field?.name,
            },
            {
              model: item => item.halfyear ? `${HALF_YEAR_DICT[item.halfyear]} полугодие ${item.year}` : item.year,
            },
            {
              model: item => {
                const field = allowedFieldsDict[item.field?.id]
                if (field?.type === 'NUMBER') {
                  const unit = field?.unit
                  const multiplier = unit?.multiplier || 1
                  return noExponents(item.value / multiplier)
                }
                return item.value
              },
            },
            {
              model: item => allowedFieldsDict[item.field?.id]?.unit?.name,
            },
            {
              className: styles.dotMenu,
              model: item => (
                <DropDownList
                  hasBackground={false}
                  hasBorder
                  sideToOpen="left"
                  HeadComponent={({ isActive }) => (
                    <Icon
                      className={isActive && styles.dotMenuActive}
                      type={ICONS_TYPES.dotMenu}
                    />
                  )}
                >
                  <Button
                    type={BUTTON_TYPES.text}
                    color={BUTTON_COLORS.blue}
                    onClick={() => {
                      const formName = 'editFieldValue' + item.id
                      const field = allowedFieldsDict[item.field?.id]
                      let transformedValue = item.value
                      if (field?.type === 'NUMBER') {
                        const unit = field?.unit
                        const multiplier = unit?.multiplier || 1
                        transformedValue = noExponents(item.value / multiplier)
                      }
                      formsStore.createForm(formName, {
                        modalComponent: 'FieldValueEditModal',
                        form: {
                          data: {
                            ...item,
                            companyId,
                            divisionId: item?.division?.id,
                            division: undefined,
                            resourceId: item.field?.resourceId,
                            fieldId: item?.field?.id,
                            unitId: allowedFieldsDict[item.field?.id]?.unit?.id,
                            field: undefined,
                            year: item.year?.toString(),
                            halfyear: item.halfyear?.toString(),
                            value: transformedValue,
                          },
                        },
                        props: {
                          standardId,
                          onSuccess: () => {
                            httpStore.resetCallTime()
                            httpStore.fetchRequest(...fieldValuesArgs)
                          },
                        },
                      })
                    }}
                  >
                    Редактировать
                  </Button>
                </DropDownList>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(DataRows)
