import { useContext } from 'react'

import { StoreContext } from '@/store'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import { ROLE_DICT } from '@/constants'

import styles from './index.module.css'


const roles = Object.entries(ROLE_DICT).map(([value, label]) => ({ value, label }))

const UserEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const userId = form.get('data.id')
  const role = form.get('data.role')
  const company = form.get('data.company.id')
  const division = form.get('data.division.id')

  const store = useContext(StoreContext)

  const { httpStore } = store

  const divisionsArgs = [
    'listDivisions',
    ['id', 'name' ],
    undefined,
  ]

  const divisions = httpStore.fetchRequest(...divisionsArgs).get('data', [])

  const companiesArgs = [
    'listCompanies',
    ['id', 'name' ],
    undefined,
  ]

  const companies = httpStore.fetchRequest(...companiesArgs).get('data', [])

  const companyReq = role !== 'SYSTEM'
  const divisionReq = !['SYSTEM', 'ADMIN'].includes(role)

  return (
    <Modal
      title="Профиль пользователя"
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: userId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(
            'upsertUser',
            undefined,
            data => {
              return {
                user: {
                  ...data,
                  role: `@enum(${data.role})`,
                  status: data.status && `@enum(${data.status})`,
                  divisionId: data.division?.id,
                  division: undefined,
                  companyId: data.company?.id,
                  company: undefined,
                },
              }
            },
          )
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        label="ФИО*"
        placeholder="ФИО"
        required
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Input
        label="Логин (email)*"
        placeholder="Почта"
        required
        type="mail"
        value={form.get('data.email', '')}
        error={form.get('errors.email', '')}
        onChange={form.set('data.email')}
        onError={form.set('errors.email')}
      />
      <Select
        required
        label="Роль*"
        placeholder="Не выбрано"
        options={roles}
        values={role ? [role] : []}
        error={form.get('errors.role', '')}
        onChange={v => form.set('data.role')(v[0])}
        onError={form.set('errors.role')}
      />
      <Select
        required={companyReq}
        label={companyReq ? 'Компания*' : 'Компания'}
        placeholder="Не выбрано"
        options={companies.map(item => ({ value: item.id, label: item.name }))}
        values={company ? [company] : []}
        error={form.get('errors.company.id', '')}
        onChange={v => form.set('data.company.id')(v[0])}
        onError={form.set('errors.company.id')}
      />
      <Select
        required={divisionReq}
        label={divisionReq ? 'Подразделение*' : 'Подразделение'}
        placeholder="Не выбрано"
        options={divisions.map(item => ({ value: item.id, label: item.name }))}
        values={division ? [division] : []}
        error={form.get('errors.division.id', '')}
        onChange={v => form.set('data.division.id')(v[0])}
        onError={form.set('errors.division.id')}
      />
    </Modal>
  )
}

export default UserEditModal
