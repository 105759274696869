import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { StoreContext } from '@/store'

import Table from '@/components/Table'
import Select from '@/components/Select'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import FileInput, { FILE_INPUT_COLORS, FILE_INPUT_TYPES } from '@/components/FileInput'

import { noExponents } from '@/utils/helpers/format'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const DataTotal = () => {
  const store = useContext(StoreContext)
  const navigate = useNavigate()
  const { standardId } = useParams()
  const [division, setDivision] = useState()

  const { httpStore, authStore, formsStore } = store

  const allowedFields = authStore?.user?.company?.allowedFields
  const companyId = authStore?.user?.company?.id
  const divisionId = authStore?.user?.division?.id

  const divisionsArgs = [
    'listDivisions',
    ['id', 'name'],
    undefined,
  ]

  const divisions = httpStore.fetchRequest(...divisionsArgs).get('data', [])

  const standardsArgs = [
    'listStandards',
    ['id', 'name', { resources: ['id', 'name'] }],
    allowedFields && { standardIds: allowedFields.map(af => af.standardId).filter((s, i, a) => a.indexOf(s) === i) },
    {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const standards = httpStore.fetchRequest(...standardsArgs).get('data', [])

  const fieldValuesArgs = [
    'listFieldValues',
    [
      'id',
      'value',
      'year',
      'halfyear',
      { division: ['id', 'name'] },
      { field: ['id', 'name', { unit: ['id', 'name'] }, 'standardId', 'resourceId', 'formula', 'significand'] },
    ],
    { standardId, companyId, departmentId: (divisionId || division) },
    {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const fieldValuesRows = httpStore.fetchRequest(...fieldValuesArgs).get('data', [])

  const fieldValues = useMemo(() => {
    const result = []
    fieldValuesRows.forEach(item => {
      const fieldValue = result.find(fv => {
        return (
          item?.field?.id === fv?.field?.id &&
          item?.division?.id === fv?.division?.id &&
          item?.year === fv?.year
        )
      })
      if (fieldValue) {
        fieldValue.value += Number.parseFloat(item.value)
      } else {
        result.push({ ...item, value: Number.parseFloat(item.value) })
      }
    })
    return result
  }, [fieldValuesRows])

  const standardsDict = useMemo(() => {
    return standards.reduce((sMemo, s) => {
      return {
        ...sMemo,
        [s.id]: {
          name: s.name,
          resources: s.resources.reduce((rMemo, r) => {
            return {
              ...rMemo,
              [r.id]: {
                name: r.name,
              },
            }
          }, {}),
        },
      }
    }, {})
  }, [standards])

  const allowedFieldsDict = useMemo(() => {
    return allowedFields.reduce((memo, curr) => ({
      ...memo,
      [curr.id]: curr,
    }), {})
  }, [allowedFields])

  const allowedStandards = useMemo(() => {
    return (allowedFields || [])
      .map(f => f.standardId)
      .filter((s, i, arr) => arr.indexOf(s) === i)
      .map(s => ({ value: s, label: standardsDict[s]?.name }))
  }, [allowedFields, standardsDict])

  useEffect(() => {
    if (allowedStandards[0]) {
      if (!standardId || !allowedStandards.some(s => s.value === standardId)) {
        navigate(`/data/total/${allowedStandards[0].value}`, { replace: true })
      }
    }
  }, [allowedStandards, navigate, standardId])

  const currResources = standardsDict[standardId]?.resources

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Сводные данные
        </h1>
        <div className={classNames(pageStyles.headerControls, styles.header)}>
          <div className={styles.helper}>
            <div>?</div>
            <a href="/air_data_template.xlsx" download>
              Скачать шаблон Excel для загрузки данных
            </a>
          </div>
          <FileInput
            type={FILE_INPUT_TYPES.dashed}
            color={FILE_INPUT_COLORS.blue}
            operationName="uploadFields"
            onChange={() => {
              httpStore.resetCallTime()
              navigate(`/data/rows/${allowedStandards[0].value}`)
            }}
            onError={({ error }) => {
              const message = error?.errors?.[0]?.message
              const formName = 'UploadDataError'
              formsStore.createForm(formName, {
                modalComponent: 'MessageBoxModal',
                props: {
                  title: 'Ошибка!',
                  text: message,
                },
              })
            }}
          >
            <span>&#x2912; Загрузить записи</span>
          </FileInput>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createFieldValue'
              formsStore.createForm(formName, {
                modalComponent: 'FieldValueEditModal',
                props: {
                  standardId,
                  onSuccess: () => {
                    httpStore.resetCallTime()
                    navigate(`/data/rows/${allowedStandards[0].value}`)
                  },
                },
              })
            }}
          >
            <span>+ Создать запись</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.filters}>
        <Select
          className={styles.standardSelect}
          options={allowedStandards}
          values={[standardId]}
          onChange={vals => navigate(`/data/total/${vals[0]}`)}
        />
        {!divisionId && (
          <Select
            placeholder="Выберите подразделение"
            className={styles.standardSelect}
            options={divisions.map(d => ({ value: d.id, label: d.name }))}
            values={division ? [division] : []}
            onChange={vals => setDivision(vals[0])}
          />
        )}
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={fieldValues}
          head={[
            {
              title: 'Стандарт',
            },
            {
              title: 'Подразделение',
            },
            {
              title: 'Название',
            },
            {
              title: 'Значение',
            },
            {
              title: 'Ед. изм.',
            },
            {
              title: 'Период',
            },
          ]}
          body={[
            {
              model: item => currResources?.[item.field?.resourceId]?.name,
            },
            {
              model: item => item.division?.name,
            },
            {
              model: item => item.field?.name,
            },
            {
              model: item => {
                const field = allowedFieldsDict[item.field?.id]
                if (field?.type === 'NUMBER') {
                  const unit = field?.unit
                  const multiplier = unit?.multiplier || 1
                  let significand = field?.significand
                  significand = significand === null || significand === undefined ? 2 : significand
                  return noExponents(item.value / multiplier, significand)
                }
                return item.value
              },
            },
            {
              model: item => allowedFieldsDict[item.field?.id]?.unit?.name,
            },
            {
              model: item => item.year,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(DataTotal)
