import { useCallback, useContext } from 'react'

import { StoreContext } from '@/store'
import { getApiUrl } from '@/store/helpers/fetch'

import Button, { BUTTON_TYPES as FILE_INPUT_TYPES, BUTTON_COLORS as FILE_INPUT_COLORS } from '../Button'

import styles from './index.module.css'


const url = getApiUrl()

const uploadFile = async({
  method = 'POST',
  data = [],
  headers,
}) => {
  try {
    let formData = new FormData()
    data.forEach(({ key, value }) => {
      formData.append(key, value)
    })

    const resp = await fetch(url, {
      method,
      headers,
      body: formData,
    })
    const contentType = resp.headers.get('content-type') || ''
    const mainType = contentType.split(';')[0]
    let respData
    switch (mainType) {
      case 'application/json':
        respData = await resp.json()
        break
      case 'text/html':
        respData = await resp.text()
        break
      case 'application/octet-stream':
        respData = await resp.blob()
        break
      default:
        respData = await resp.text()
    }

    const error = resp.status >= 400
    if (error) {
      return Promise.reject({ status: resp.status, error: respData })
    }
    return { status: resp.status, data: respData }
  } catch {
    return Promise.reject({
      status: 0,
      error: {
        data: {
          error: 'Connection error',
        },
      },
    })
  }
}

const FileInput = ({
  onChange = () => {},
  onError = () => {},
  onClick,
  link,
  multiple,
  operationName,
  ...props
}) => {
  const store = useContext(StoreContext)
  const { authStore: { token } } = store

  const handleChange = useCallback(({ target }) => {
    const { files } = target
    if (!operationName) {
      onChange(files)
      return
    }
    const data = []
    if (multiple) {
      // TODO
    } else {
      data.push({
        key: 'operations',
        // eslint-disable-next-line max-len
        value: `{"query":"mutation UploadFile($file: Upload!) {${operationName}(file: $file)}","operationName":"UploadFile","variables":{"file":null}}`,
      })
      data.push({
        key: 'map',
        value: '{"0":["variables.file"]}',
      })
      data.push({
        key: '0',
        value: files[0],
      })
    }
    const headers = {
      Authorization: token,
      'x-apollo-operation-name': operationName,
    }
    return uploadFile({ data, headers })
      .then(onChange)
      .catch(onError)
  }, [onChange, onError, multiple, token, operationName])
  return (
    <label>
      <input type="file" multiple={multiple} className={styles.fileInput} onChange={handleChange} />
      <Button {...props} />
    </label>
  )
}

export default FileInput

export { FILE_INPUT_TYPES, FILE_INPUT_COLORS }
