import { useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { EMPTY_VALUE_ERR_MSG } from '@/constants'

import styles from './index.module.css'


const Toggler = ({
  className,
  label,
  disabled,
  value,
  error,
  required,
  checkOnBlur = true,
  onChange,
  onError,
}) => {
  useEffect(() => {
    let errorMsg
    if (required && onError) {
      errorMsg = value ? undefined : EMPTY_VALUE_ERR_MSG
    }
    if (error !== errorMsg) {
      onError(errorMsg)
    }
  }, [value, error, required, onError])

  const handleChange = useCallback(e => {
    onChange(e.target.checked)
  }, [onChange])

  return (
    <label className={classNames(styles.root, className, value && styles.checked)}>
      <input type="checkbox" checked={value} onChange={handleChange} disabled={disabled}/>
      <div className={styles.toggler} />
      {label && (
        <div className={styles.label}>{label}</div>
      )}
    </label>
  )
}

export default Toggler
