import { useContext, useEffect } from 'react'

import { StoreContext } from '@/store'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import { FIELD_TYPE_DICT } from '@/constants'

import styles from './index.module.css'


const types = Object.entries(FIELD_TYPE_DICT)
  .filter(([value]) => value !== 'null')
  .map(([value, label]) => ({ value, label }))

const FieldEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const store = useContext(StoreContext)
  const { httpStore, authStore } = store

  const userRole = authStore.user?.role
  const userCompany = authStore.user?.company?.id
  
  const fieldId = form.get('data.id')
  const moduleId = form.get('data.moduleId')
  const companyId = form.get('data.companyId')
  const standardId = form.get('data.standardId')
  const resourceId = form.get('data.resourceId')
  const type = form.get('data.type')
  const unitId = form.get('data.unitId')

  const canSetCompany = userRole === 'SYSTEM'

  useEffect(() => {
    if (!fieldId && !canSetCompany) {
      form.set('data.companyId')(userCompany)
    }
  }, [userCompany, form, fieldId, canSetCompany])

  if (!moduleId) {
    return (
      <Modal
        title="Ошибка"
        type="center"
        className={styles.root}
        width={460}
        onClose={onClose}
      >
        Ошибка
      </Modal>
    )
  }

  let companies = []
  if (canSetCompany) {
    const companiesArgs = [
      'listCompanies',
      [
        'id',
        'name',
      ],
      undefined,
      {
        cacheTime : 1000 * 60 * 60,
      },
    ]
    companies = httpStore.fetchRequest(...companiesArgs).get('data', [])
      .map(u => ({ value: u.id, label: u.name }))
  }

  const unitsArgs = [
    'listUnits',
    [
      'id',
      'name',
    ],
    undefined,
    {
      cacheTime : 1000 * 60 * 60,
    },
  ]
  const units = httpStore.fetchRequest(...unitsArgs).get('data', [])
    .map(u => ({ value: u.id, label: u.name }))

  const modulesArgs = [
    'viewModule',
    [
      'id',
      'name',
      {
        standards: [
          'id',
          'name',
          {
            resources: [
              'id',
              'name',
            ],
          },
        ],
      },
    ],
    { 'moduleId': moduleId },
    {
      cacheTime : 1000 * 60 * 60,
    },
  ]
  const module = httpStore.fetchRequest(...modulesArgs).get('data', {})
  const standardsDict = (module?.standards || []).reduce((sm, s) => {
    if (!s.resources?.length) return sm
    return {
      ...sm,
      [s.id]: {
        value: s.id,
        label: s.name,
        resources: s.resources,
      },
    }
  }, {})

  const standards = Object.values(standardsDict)
  const resources = (standardsDict[standardId]?.resources || [])
    .map(r => ({ value: r.id, label: r.name }))

  return (
    <Modal
      title="Параметр"
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: fieldId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(
            'upsertField',
            undefined,
            data => {
              return {
                field: {
                  ...data,
                  moduleId: undefined,
                  standardId: undefined,
                },
              }
            },
          )
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      {canSetCompany && (
        <Select
          label="Компания"
          placeholder="Не выбрано"
          options={companies}
          values={companyId ? [companyId] : []}
          error={form.get('errors.companyId', '')}
          onChange={v => form.set('data.companyId')(v[0])}
          onError={form.set('errors.companyId')}
        />
      )}
      <Input
        label="Название*"
        placeholder="Название"
        required
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Select
        label="Тип данных*"
        placeholder="Не выбрано"
        required
        options={types}
        values={type ? [type] : []}
        error={form.get('errors.type', '')}
        onChange={v => form.set('data.type')(v[0])}
        onError={form.set('errors.type')}
      />
      <Select
        label="Единицы измерения*"
        placeholder="Не выбрано"
        required
        options={units}
        values={unitId ? [unitId] : []}
        error={form.get('errors.unitId', '')}
        onChange={v => form.set('data.unitId')(v[0])}
        onError={form.set('errors.unitId')}
      />
      <Select
        label="Стандарт*"
        placeholder="Не выбрано"
        required
        options={standards}
        values={standardId ? [standardId] : []}
        error={form.get('errors.standardId', '')}
        onChange={v => form.set('data.standardId')(v[0])}
        onError={form.set('errors.standardId')}
      />
      {standardId && (
        <Select
          label="Ресурс*"
          placeholder="Не выбрано"
          required
          options={resources}
          values={resourceId ? [resourceId] : []}
          error={form.get('errors.resourceId', '')}
          onChange={v => form.set('data.resourceId')(v[0])}
          onError={form.set('errors.resourceId')}
        />
      )}
    </Modal>
  )
}

export default FieldEditModal
