import { types, getSnapshot, getParent, isAlive, isArrayType, getType, detach } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import set from 'lodash/set'

import { getDeepMap } from '../helpers/types'
import { hasErrors, normalize } from '../helpers/functions'
import { callApi, getApiUrl } from '../helpers/fetch'
import { toGraphBody, toGraphQuery } from '../helpers/graphql'


let store

const url = getApiUrl()

import('../index').then(module => {
  store = module.getStore()
})


export const Form = types.model('Form', {
  data: types.map(getDeepMap(5)),
  errors: types.map(getDeepMap(5)),
}).views(self => ({
  get(path, defaultValue, toJSON = true) {
    if (getParent(self).detached) return defaultValue
    const pathArray = path.split('.')
    const value = pathArray.reduce((memo, key, i) => {
      if (!i) return memo
      if (typeof memo?.get === 'function') return memo.get(key)
      if ((memo || {})[key]) return memo[key]
      return undefined
    }, self[pathArray[0]]) || defaultValue
    try {
      if (toJSON) {
        return getSnapshot(value)
      }
      return value
    } catch {
      return value
    }
  },
  get hasErrors() {
    return hasErrors(getSnapshot(self.errors))
  },
})).actions(self => ({
  setValue(path, value) {
    if (isAlive(self) && path) {
      const pathArray = path.split('.')
      if (pathArray[0] !== 'errors') self.errors.set('globalError', undefined)
      const lastKey = pathArray.pop()
      let target = self
      pathArray.forEach((key, i) => {
        if (target) {
          let nextTarget
          if (typeof target.get === 'function') {
            nextTarget = target.get(key, undefined, false)
          } else {
            nextTarget = target[key]
          }
          if (!nextTarget) {
            const newPath = [...pathArray.slice(i + 1), lastKey].join('.')
            if (typeof target.set === 'function') {
              target.set(key, normalize(set({}, newPath, value)))
            } else if (isArrayType(getType(target))) {
              const index = +key
              if (Number.isNaN(index)) {
                throw new Error('Wrong path name')
              }
              if (index > target.length) {
                for (let i = 0; i < index; i += 1) {
                  target[i] = target[i] || undefined
                }
              }
              target[key] = normalize(set({}, newPath, value))
            } else {
              target[key] = normalize(set({}, newPath, value))
            }
          }
          target = nextTarget
        }
      })
      if (target) {
        if (target === self) {
          target[lastKey] = value
        } else if (typeof target.set === 'function') {
          target.set(lastKey, value)
        } else {
          target[lastKey] = value
        }
      }
    }
  },
  set(path) {
    return value => self.setValue(path, value)
  },
  insertToArray(path, index, value) {
    const current = self.get(path, [])
    if (!Array.isArray(current)) {
      return Promise.reject('Value is not array')
    }
    const newArray = [
      ...current.slice(0, index),
      value,
      ...current.slice(index),
    ]
    self.setValue(path, newArray)
  },
  removeFromArray(path, index) {
    const current = self.get(path, [])
    if (!Array.isArray(current)) {
      return Promise.reject('Value is not array')
    }
    const newArray = current.filter((_, i) => i !== index)
    self.setValue(path, newArray)
  },
  submit(method, fields = ['id'], transform = v => v, options = {}) {
    const data = getSnapshot(self.data)
    const token = store.authStore.token
    let body
    const { headers = {} } = options
    if (!headers['Authorization'] && token) {
      headers.Authorization = token
    }
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json'
    }
    if (headers['Content-Type'] === 'application/json') {
      body = {
        query: `mutation { ${method}(${toGraphBody(transform(data))}) { ${toGraphQuery(fields)} } }`,
      }
    }

    return callApi(url, {
      ...options,
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
      .then(({ status, data }) => ({
        status,
        data: data?.data?.[method],
      }))
      .catch(error => {
        try {
          const errors = error?.error?.errors
          console.error(errors)
          const globalError = errors?.[0]?.message
          if (globalError) {
            self.setValue('errors.globalError', globalError)
          }
        } catch {
          console.error(error)
        }
        return Promise.reject(error)
      })
  },
}))

const FormStoreItem = types.model('FormStoreItem', {
  modalComponent: types.maybe(types.string),
  props: types.maybe(types.frozen()),
  form: Form,
  detached: types.optional(types.boolean, false),
})
  .actions(self => ({
    setDetached(detached = true) {
      self.detached = detached
    },
  }))

const FormStore = types.model('FromStore', {
  forms: types.map(FormStoreItem),
})
  .views(self => ({
    getForm(name) {
      return self.forms.get(name)
    },
  }))
  .actions(self => ({
    createForm(name, { modalComponent, form = {}, props } = {}, update) {
      if (update || !self.forms.has(name) || self.forms.get(name).detached) {
        self.forms.set(name, { modalComponent, form, props, detached: false })
      }
      return self.forms.get(name)
    },
    sendQuickForm(initFormData, url, method, options) {
      const { form } = self.createForm(uuid(), initFormData)
      return form.submit(url, method, options)
    },
    removeForm(name) {
      if (self.forms.has(name)) {
        const fs = self.forms.get(name)
        fs.setDetached()
        detach(fs)
      }
    },
  }))

export default FormStore
