import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './index.module.css'
import { BUTTON_TYPES, BUTTON_COLORS } from './constants'

const Button = ({
  className,
  type = BUTTON_TYPES.fill,
  color = BUTTON_COLORS.main,
  disabled,
  link,
  children,
  ...otherProps
}) => {

  const classes = classNames(
    styles.root,
    styles[type],
    !link && disabled && styles.disabled,
    styles[color],
    className
  )

  if (children && link) {
    return (
      <Link
        {...otherProps}
        to={link}
        className={classes}
      >
        {children}
      </Link>
    )
  }

  if (children && !link && otherProps.onClick) {
    return (
      <button
        {...otherProps}
        className={classes}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }

  if (children && !link && !otherProps.onClick) {
    return (
      <div
        {...otherProps}
        className={classes}
      >
        {children}
      </div>
    )
  }

  return null
}

export default Button

export { BUTTON_TYPES, BUTTON_COLORS } from './constants'
