import { useContext, useEffect, useMemo } from 'react'

import { StoreContext } from '@/store'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import { HALF_YEAR_DICT } from '@/constants'

import styles from './index.module.css'


const halfyears = Object.entries(HALF_YEAR_DICT)
  .map(([value, label]) => ({ value, label }))

const FieldEditModal = ({ children, onClose, form, onSuccess, standardId, ...other }) => {
  const store = useContext(StoreContext)
  const { httpStore, authStore } = store

  const userRole = authStore.user?.role
  const userCompany = authStore.user?.company?.id
  const userDivision = authStore.user?.division?.id

  const canSetCompany = userRole === 'SYSTEM'
  const canSetDivision = canSetCompany || userRole === 'ADMIN'
  
  const valueId = form.get('data.id')
  const companyId = form.get('data.companyId')
  const divisionId = form.get('data.divisionId')
  const resourceId = form.get('data.resourceId')
  const fieldId = form.get('data.fieldId')
  const unitId = form.get('data.unitId')
  const halfyear = form.get('data.halfyear')

  let companies = []
  let divisions = []
  let resources = []
  let company

  useEffect(() => {
    if (!valueId && !canSetCompany) {
      form.set('data.companyId')(userCompany)
    }
  }, [userCompany, form, valueId, canSetCompany])

  useEffect(() => {
    if (!valueId && !canSetDivision) {
      form.set('data.divisionId')(userDivision)
    }
  }, [userDivision, form, valueId, canSetDivision])

  const unitsArgs = [
    'listUnits',
    [ 'id', 'name', 'parentId', 'multiplier' ],
    undefined,
    { cacheTime : 1000 * 60 * 60 },
  ]
  const unitsFull = httpStore.fetchRequest(...unitsArgs).get('data', [])

  const standardsArgs = [
    'listStandards',
    ['id', 'name', { resources: ['id', 'name'] }],
    undefined, {
      cacheTime: 60 * 60 * 1000,
    },
  ]
  const standards = httpStore.fetchRequest(...standardsArgs).get('data', [])

  const standardsDict = useMemo(() => {
    return standards.reduce((sMemo, s) => {
      return {
        ...sMemo,
        [s.id]: {
          name: s.name,
          resources: s.resources.reduce((rMemo, r) => {
            return {
              ...rMemo,
              [r.id]: {
                name: r.name,
              },
            }
          }, {}),
        },
      }
    }, {})
  }, [standards])

  if (canSetCompany) {
    const companiesArgs = [
      'listCompanies',
      [
        'id',
        'name',
        { divisions: [ 'id', 'name' ] },
        {
          allowedFields: [
            'id',
            'name',
            'type',
            'formula',
            'significand',
            { unit: ['id', 'name', 'multiplier', 'parentId'] },
            'orderNumber',
            'resourceId',
            'standardId',
            'companyId',
          ],
        },
      ],
      undefined,
      {
        cacheTime : 1000 * 60 * 60,
      },
    ]
    companies = httpStore.fetchRequest(...companiesArgs).get('data', [])
      .map(c => ({ value: c.id, label: c.name, divisions: c.divisions, allowedFields: c.allowedFields }))
    if (companyId) {
      company = companies.find(c => c.value === companyId)
      divisions = (company?.divisions || [])
        .map(c => ({ value: c.id, label: c.name }))
    }
  } else {
    const companiesArgs = [
      'listCompanies',
      [
        'id',
        'name',
        { divisions: [ 'id', 'name' ] },
        {
          allowedFields: [
            'id',
            'name',
            'type',
            'formula',
            'significand',
            { unit: ['id', 'name', 'multiplier', 'parentId'] },
            'orderNumber',
            'resourceId',
            'standardId',
            'companyId',
          ],
        },
      ],
      { companyId: userCompany },
      {
        cacheTime : 1000 * 60 * 60,
      },
    ]
    companies = httpStore.fetchRequest(...companiesArgs).get('data', [])
      .map(c => ({ value: c.id, label: c.name, divisions: c.divisions, allowedFields: c.allowedFields }))
    company = companies?.[0]
    if (canSetDivision) {
      divisions = (company?.divisions || [])
        .map(c => ({ value: c.id, label: c.name }))
    }
  }

  const allowedFields = company?.allowedFields

  resources = useMemo(() => {
    if (!standardId) return []
    const currStandard = standardsDict[standardId] || {}
    const stResources = currStandard.resources || {}
    return (allowedFields || [])
      .map(f => f.resourceId)
      .filter((r, i, arr) => arr.indexOf(r) === i && stResources[r])
      .map(r => ({
        value: r,
        label: stResources[r]?.name,
        fields: (allowedFields || []).filter(f => f.resourceId === r && !f.formula),
      }))
  }, [standardId, allowedFields, standardsDict])

  const resource = resources.find(r => r.value === resourceId)
  const fields = (resource?.fields || [])
    .map(f => ({ value: f.id, label: f.name, unit: f.unit, type: f.type }))
  const field = fields.find(f => f.value === fieldId)
  const defaultUnit = field?.unit

  useEffect(() => {
    if (!valueId) {
      form.set('data.unitId')(defaultUnit?.id)
    }
  }, [form, valueId, defaultUnit?.id])

  const units = unitsFull
    .filter(u =>
      u.id === defaultUnit?.id ||
      u.parentId === defaultUnit?.id ||
      u.id === defaultUnit?.parentId ||
      (defaultUnit?.parentId && u.parentId === defaultUnit?.parentId))
    .map(u => ({ value: u.id, label: u.name }))
  const unit = unitId && unitsFull.find(u => u.id === unitId)

  return (
    <Modal
      title="Параметр"
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: valueId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(
            'upsertFieldValue',
            undefined,
            data => {
              return {
                fieldValue: {
                  ...data,
                  value: field?.type === 'NUMBER' ? (data.value * (unit.multiplier || 1)).toString() : data.value,
                  year: Number.parseInt(data.year),
                  halfyear: Number.parseInt(data.halfyear),
                  companyId: undefined,
                  unitId: undefined,
                  resourceId: undefined,
                },
              }
            },
          )
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      {canSetCompany && (
        <Select
          required
          label="Компания*"
          placeholder="Не выбрано"
          options={companies}
          values={companyId ? [companyId] : []}
          error={form.get('errors.companyId', '')}
          onChange={v => form.set('data.companyId')(v[0])}
          onError={form.set('errors.companyId')}
        />
      )}
      {!!companyId && canSetDivision && (
        <Select
          required
          label="Подразделение*"
          placeholder="Не выбрано"
          options={divisions}
          values={divisionId ? [divisionId] : []}
          error={form.get('errors.divisionId', '')}
          onChange={v => form.set('data.divisionId')(v[0])}
          onError={form.set('errors.divisionId')}
        />
      )}
      {!!companyId && (
        <Select
          label="Ресурс*"
          placeholder="Не выбрано"
          required
          options={resources}
          values={resourceId ? [resourceId] : []}
          error={form.get('errors.resourceId', '')}
          onChange={v => form.set('data.resourceId')(v[0])}
          onError={form.set('errors.resourceId')}
        />
      )}
      {!!resourceId && (
        <Select
          label="Параметр*"
          placeholder="Не выбрано"
          required
          options={fields}
          values={fieldId ? [fieldId] : []}
          error={form.get('errors.fieldId', '')}
          onChange={v => form.set('data.fieldId')(v[0])}
          onError={form.set('errors.fieldId')}
        />
      )}
      {!!fieldId && (
        <Input
          label="Значение*"
          placeholder="Значение"
          allowRegex={field?.type === 'NUMBER' ? /[\d.]/ : undefined}
          required
          value={form.get('data.value', '')}
          error={form.get('errors.value', '')}
          onChange={form.set('data.value')}
          onError={form.set('errors.value')}
        />
      )}
      {!!fieldId && (
        <Select
          label="Единицы измерения*"
          placeholder="Не выбрано"
          required
          options={units}
          values={unitId ? [unitId] : []}
          error={form.get('errors.unitId', '')}
          onChange={v => form.set('data.unitId')(v[0])}
          onError={form.set('errors.unitId')}
        />
      )}
      {!!fieldId && (
        <Input
          label="Год*"
          placeholder={(new Date()).getFullYear().toString()}
          allowRegex={/\d/}
          required
          value={form.get('data.year', '')}
          error={form.get('errors.year', '')}
          onChange={form.set('data.year')}
          onError={form.set('errors.year')}
        />
      )}
      {!!fieldId && (
        <Select
          label="Полугодие*"
          placeholder="Не выбрано"
          required
          options={halfyears}
          values={halfyear ? [halfyear] : []}
          error={form.get('errors.halfyear', '')}
          onChange={v => form.set('data.halfyear')(v[0])}
          onError={form.set('errors.halfyear')}
        />
      )}
    </Modal>
  )
}

export default FieldEditModal
